import template from './new_password.html';
import CryptoUtils from '../lib/CryptoUtils.js';

class NewPasswordViewModel
{
	constructor(page)
	{

		this.page = page;

		this.password1 = ko.observable('');
		this.password2 = ko.observable('');

		this.alert_message = ko.observable('');

		this.valid = ko.computed(() => {
			if (this.password1() != this.password2())
				return false;

			if (this.password1().length < 6)
				return false;

			return true;
		});

		this.password1.subscribe(function(nv) {
		});
		this.password2.subscribe(function(nv) {
		});
	}
};

class NewPasswordPage
{
	constructor (bindings) 
	{
		this.name = 'NewPasswordPage';
		this.viewModel = new NewPasswordViewModel(this);
	}

	init()
	{
	}

	updateData(bindings, params, options, element)
	{
		this.bindings = bindings;
	}

	async reset_click ()
	{
		if (this.viewModel.password1() != this.viewModel.password2())
		{
			self.viewModel.alert_message('The two passwords you entered does not match');
			return;
		}

		let s2ksalt = CryptoUtils.generateRandomBytes(10);
		let s2kparams = {
			's2k-scheme': 'pbkdf2',
			's2k-rounds': 1000,
			's2k-digest': 'sha256',
			's2k-salt': CryptoUtils.encodeBinary(s2ksalt, 'hex')
		};
		let key = await CryptoUtils.generateString2Key(this.bindings.p, s2kparams);

		let encrypted_message = await CryptoUtils.encryptMessage({algo: 'aes', data: this.viewModel.password1()}, key);
		encrypted_message = Object.assign(encrypted_message, s2kparams);

		console.log('ri=',this.bindings.ri);

		let json = await Grape.fetches.postJSON('/api/user/reset-password', {
			ri: this.bindings.ri,
			new_password: encrypted_message
		});
		if (json.status == 'OK')
		{
			Grape.alerts.alert({
				type: 'success', 
				title: 'Password Reset', 
				message: 'Your password was reset successfully', 
				onClose: (d) => {}
			});
			Grape.navigate_to_default();

		}
		else
		{
			Grape.alerts.alert({
				type: 'error', 
				title: 'Password Reset Error', 
				message: 'Error while resetting your password: ' + json.message
			});

		}
	}
};

export default {
	route: '[/]grape-ui/ps-new_password',
	page_class: NewPasswordPage,
	template: template
};

