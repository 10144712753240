
import './pbkdf2.js';
import aesjs from 'aes-js';
import jsSHA from 'jssha';

class AESProvider
{
	static encrypt(data, key, iv, mode, padding)
	{
		if (mode == 'cbc')
		{
			let aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
			if (padding == 'pkcs')
			{
				data = aesjs.padding.pkcs7.pad(data);
			}
			let encrypted_data = aesCbc.encrypt(data);
			return encrypted_data;
		}
		else
		{
			throw new Error('Unrecognized AES mode "' + mode + '"');
		}
	}

	static async decrypt(data, key, iv, mode, padding)
	{
		let aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
		let decrypted_data = aesCbc.decrypt(data);
		//console.log('decrypted_data', decrypted_data);
		//try {
		//	console.log('text data', new TextDecoder('utf8').decode(data));
		//} catch (err) { console.error(err); }
		if (padding == 'pkcs')
			decrypted_data = aesjs.padding.pkcs7.strip(decrypted_data);
		return decrypted_data;
	}
}

class CryptoUtils
{
	constructor()
	{
	}

	/**
	 * Decrypt message object
	 * @param {MessageType} msg 
	 * @param {string} algo - Algorithm, only aes is supported at this stage
	 * @param {OptionalEncodedString} data - Contains encrypted data, can optionally be a string starting with 'hex:' or 'base64:', followed with the encoded data
	 * @param {OptionalEncodedString} iv - Contains IV, can optionally be a string starting with 'hex:' or 'base64:', followed with the encoded IV
	 */
	static async decryptMessage(msg, key)
	{
		switch (msg.algo)
		{
			case 'aes':
				let data = await AESProvider.decrypt(
					CryptoUtils.decodeBinary(msg.data),
					key,
					CryptoUtils.decodeBinary(msg.iv),
					msg.mode || 'cbc',
					msg.padding || 'pkcs'
				);
				let strdata;
				try {
					strdata = new TextDecoder().decode(data);
					console.log('strdata=',strdata);
				} catch (err) { 
					console.log('err=',err);
				}
				return strdata;
			default:
				throw new Error('Unsupported decryption algorithm "' + msg.algo + '"');
		}
	}

	// Decode string from binary encoding format (hex or base64)
	static decodeBinary(str, encoding=null)
	{
		if (encoding === null)
		{
			if (str.startsWith('hex:'))
			{
				encoding = 'hex';
				str = str.substring(4);
			}
			else if (str.startsWith('base64:'))
			{
				encoding = 'base64';
				str = str.substring(7);
			}
			else
				return str;
		}

		if (encoding == 'hex')
			return aesjs.utils.hex.toBytes(str);
		else if (encoding == 'base64')
		{
			let binstr = window.atob(str);
			let buf = new Array(binstr.length);
			Array.prototype.forEach.call(binstr, ((ch, i) => {
				buf[i] = ch.charCodeAt(0);
			}));
			return buf;
		}
		else
			throw new Error('No/Invalid encoding provided');
	}

	static async digest(data, algo)
	{
		if (algo.toUpperCase().startsWith('SHA'))
		{
			switch (algo.toUpperCase())
			{
				case 'SHA256':
				case 'SHA-256':
					let shaObj = new jsSHA('SHA-256', 'TEXT');
					shaObj.update(data);
					return shaObj.getHash('UINT8ARRAY');
				default:
					throw new Error('Unspported digest algorithm "' + algo + '"');
			}
		}
		else if (algo.toUpperCase() == 'MD5')
		{
			return window.MD5(data);
		}
		else
			throw new Error('No/Invalid digest algorithm provided "' + algo + '"');
	}

	static encodeBinary(str, encoding)
	{
		console.log('encodeBinary(' + str + ')');
		if (encoding == 'hex')
			return 'hex:' + aesjs.utils.hex.fromBytes(str);
			//return 'hex:' + aesjs.utils.hex.fromBytes(aesjs.utils.utf8.toBytes(str));
		else if (encoding == 'base64')
			return 'base64:' + btoa(str);
		else
			throw new Error(`Invalid encoding "${encoding}"`);
	}
	
	static async encryptMessage(msg, key)
	{
		switch (msg.algo)
		{
			case 'aes':
				let iv;
				if (msg.hasOwnProperty('iv'))
					iv = CryptoUtils.decodeBinary(msg.iv);
				else
					iv = CryptoUtils.generateRandomBytes(16);

				let rawdata = CryptoUtils.decodeBinary(msg.data);

				if (typeof rawdata == 'string')
					rawdata = aesjs.utils.utf8.toBytes(rawdata);

				let output_encoding = msg.output_encoding || 'hex';

				let data = await AESProvider.encrypt(
					rawdata,
					key,
					iv,
					msg.mode || 'cbc',
					msg.padding || 'pkcs'
				);
				return {
					data: CryptoUtils.encodeBinary(data, output_encoding),
					iv: CryptoUtils.encodeBinary(iv, output_encoding),
					algo: msg.algo,
					mode: msg.mode || 'cbc',
					padding: msg.padding || 'pkcs'
				};
			default:
				throw new Error('Unsupported decryption algorithm "' + msg.algo + '"');
		}
	}

	static async generateString2Key(str, s2kparams)
	{
		let key = '';
		if (s2kparams['s2k-scheme'] == 'pbkdf2')
		{
			let digest = s2kparams['s2k-digest'].toUpperCase();
			let rounds = s2kparams['s2k-rounds'];
			let salt = CryptoUtils.decodeBinary(s2kparams['s2k-salt']);

			// Use correct algorithm names for jsSHA
			if (digest == 'PBKDF2SHA256' || digest == 'SHA256')
				digest = 'SHA-256';
			else if (digest == 'SHA128')
				digest = 'SHA-128';

			key = window.PBKDF2(digest, str, salt, rounds, 32, 20);
		}
		else
		{
			throw new Error('Only valid value currently for s2k-scheme is "pbkdf2"');
		}

		return key;
	}

	static generateRandomBytes(length)
	{
		let bytes = new Uint8Array(length);
		window.crypto.getRandomValues(bytes);
		return bytes;
	}

	static arrayToHex(arr)
	{
		let ret = '';
		for (let i = 0; i < arr.length; i++)
		{
			let hex = (arr[i] & 0xff).toString(16);
			if (arr[i] < 16)
				hex = '0' + hex;
			ret += hex;
		}
		return ret;
	}
}

export default CryptoUtils;

