
import CryptoUtils from './CryptoUtils.js';

class GrapeLogin
{
	constructor()
	{
		this.reset();
	}

	reset()
	{
		this.status = '';
		this.userRequest = {};
		this.serviceParams = {};
		this.redirectURL = null;
		this.userOtpMethods = [];
		this.encrypted_tgt_container = null;
		this.decrypted_tgt_container = null;
		this.password = null;
		this.otp = {};
		this.sessionKey = null;
	}

	// Authentication request
	async authRequest(username)
	{
		username = username.trim();
		if (username.trim() == '')
			throw new Error('Invalid username/email provided');

		this.userRequest = {};
		this.userOtpMethods = [];
		this.otp = {};

		if (username.indexOf('@') > -1)
			this.userRequest.email = username.toLowerCase();
		else
			this.userRequest.username = username;
		
		let result;
		try {
			result = await Grape.fetches.getJSON('/api/authenticate', this.userRequest, {cache: 'no-cache'});
			if (result.status == 'OK')
			{
				if (result.auth_mech == 'local')
				{
					this.encrypted_tgt_container = result.params.tgt_container;
					this.userOtpMethods = result.params.otp_methods || [];
					for (let otp of this.userOtpMethods)
						this.otp[otp] = null;

					this.status = 'READY';
				}
				else if (result.auth_mech == 'remote')
				{
					this.status = 'REDIRECT';
					this.serviceParams = result.params?.service_params || {};
					if (this.serviceParams.loginRedirectURL && this.serviceParams.uuid)
					{
						this.redirectURL = `${this.serviceParams.loginRedirectURL}?service=${this.serviceParams.uuid}`; // TODO generate this server-side
					}
				}
			}
			else
			{
				this.status = 'ERROR';
				console.error(result);
			}
		} catch (err) {
			this.status = 'ERROR';
			console.error(err);
		}
		return this.status;
	}

	// TGT container contains: Session Key and Encrypted TGT.
	// The Session Key is used to encrypt the Authenticator.
	// The TGT container can be decrypted using a derived key of the user's password.
	async decryptTGTContainer()
	{
		if (!this.password)
			throw new Error('Missing Password');

		let etgtc = this.encrypted_tgt_container;
		let key = null;
		if (etgtc.hasOwnProperty('s2k-scheme'))
		{
			let s2kparams = {};
			for (let [key, value] of Object.entries(etgtc))
				if (key.startsWith('s2k-'))
					s2kparams[key] = value;
			key = await CryptoUtils.generateString2Key(this.password, s2kparams);
		}
		else
		{
			key = this.password;
		}

		let decrypted_tgt_container = await CryptoUtils.decryptMessage(etgtc, key);
		decrypted_tgt_container = JSON.parse(decrypted_tgt_container);
		// Session key is retrieved from a decrypted TGT container. This is used to encrypt the Authenticator
		this.sessionKey = CryptoUtils.decodeBinary(decrypted_tgt_container.session_key);

		this.decrypted_tgt_container = decrypted_tgt_container;

		return decrypted_tgt_container;
	}

	// Authenticator contains the user information (username or email and issued time) and is encrypted with the session key
	async generateEncryptedAuthenticator()
	{
		if (!this.sessionKey)
			throw new Error('Missing Session Key');

		let authenticator = Object.assign({
			issued_at: (new Date()).toISOString(),
		}, this.userRequest);

		let s2ksalt = CryptoUtils.generateRandomBytes(10);
		let s2kparams = {
			's2k-scheme': 'pbkdf2',
			's2k-rounds': 1000,
			's2k-digest': 'sha256',
			's2k-salt': CryptoUtils.encodeBinary(s2ksalt, 'hex')
		};
		let key = await CryptoUtils.generateString2Key(this.sessionKey, s2kparams);

		let encrypted_authenticator = await CryptoUtils.encryptMessage({algo: 'aes', data: JSON.stringify(authenticator)}, key);
		encrypted_authenticator = Object.assign(encrypted_authenticator, s2kparams);

		return encrypted_authenticator;
	}

	// Acquire a session using the TGT from a decrypted TGT container
	async loginWithTGT()
	{
		let result;
		let authenticator = await this.generateEncryptedAuthenticator();
		try {
			result = await Grape.fetches.postJSON('/api/authenticate/tgt', {
				tgt: this.decrypted_tgt_container.encrypted_tgt,
				authenticator: authenticator,
				otp: this.otp
			});
		} catch (err) {
			this.status = 'ERROR';
			console.error(err);
		}
		return result;
	}
}

export default GrapeLogin;

