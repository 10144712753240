import JSSHA from 'jssha';

(function () {
	/**
	 * PRF: algorithm (like SHA-256)
	 * Password: key
	 * Salt: Uint8Array/String containing the salt to use. If a string is provided it must not be encoded
	 * c: Counts
	 * dkLen: desired length
	 * hLen: hash length
	 */
	function PBKDF2(PRF, Password, Salt, c, dkLen, hLen) {
		function toBytes(text) {
			// credits to aes-js
			var result = [];
			var i = 0;
			text = encodeURI(text);
			for (var i = 0; i < text.length; i++)
			{
				var c = text.charCodeAt(i);

				// if it is a % sign, encode the following 2 bytes as a hex value
				if (c === 37)
				{
					result.push(parseInt(text.substr(i, 2), 16));
					i += 2;
				}
				else

					result.push(c);
			}

			return new Uint8Array(result);
		}

		function HMAC(key, str, algo) {
			if (typeof str === 'string')
				str = toBytes(str);
			if (typeof key === 'string')
				key = toBytes(key);

			var shaObj = new JSSHA(algo, 'ARRAYBUFFER');
			shaObj.setHMACKey(key, 'ARRAYBUFFER');
			shaObj.update(str);
			return new Uint8Array(shaObj.getHMAC('ARRAYBUFFER'));
		}


		function arrayToHex(arr) {
			var ret = '';
			for (var i = 0; i < arr.length; i++)
			{
				var hex = (arr[i] & 0xff).toString(16);
				if (arr[i] < 16)
					hex = '0' + hex;
				// console.log(arr[i], hex);
				ret += hex;
			}
			return ret;
		}

		function XOR(a, b) {
			var buf = new ArrayBuffer(Math.min(a.length, b.length));
			var res = new Uint8Array(buf);

			for (var i = 0; i < a.length && i < b.length; i++)
				res[i] = (a[i] ^ b[i]);
			return res;
		}

		function joinArrays(a, b) {
			var buf = new ArrayBuffer(a.length + b.length);
			var c = new Uint8Array(buf);
			c.set(a);
			c.set(b, a.length);
			return c;
		}

		function F(PRF, Password, Salt, c, i) {
			var packed = new Uint8Array([
				(i & 0xFF000000) >> 24,
				(i & 0xFF0000) >> 16,
				(i & 0xFF00) >> 8,
				(i & 0xFF)
			]);
			var U1 = PRF(Password, joinArrays(Salt, packed));
			var Uj = U1;

			for (var j = 1; j < c; j++)
			{
				Uj = PRF(Password, Uj);
				U1 = XOR(U1, Uj);
			}
			return U1;
		}

		if (typeof Salt === 'string')
			Salt = toBytes(Salt);

		if (typeof PRF === 'string')
		{
			var algo = PRF;
			PRF = function (p1, p2) { return HMAC(p1, p2, algo); };
		}

		var blocks = Math.ceil(dkLen / hLen);
		var res = [];
		for (var i = 1; i <= blocks; i++)

			res = joinArrays(res, F(PRF, Password, Salt, c, i));

		return res.subarray(0, dkLen);
	}

	window.PBKDF2 = PBKDF2;
})();
