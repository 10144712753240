
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import jsSHA from 'jssha';
import Plugin from './lib/Plugin.js';
import './css/ps-login.css';
import PackageJSON from '../package.json';

window.JSSHA = jsSHA;

document.addEventListener('DOMContentLoaded', () => {

	window.Grape.modules.push(PackageJSON);

	window.Grape.cache.registerCaches(appcaches);
	window.Grape.component.registerComponents(components);
	window.Grape.dialog.registerDialogs(dialogs);
	window.Grape.pages.registerPages(pages);

	Grape.plugins.register(Plugin);
});

if (!window.UserAccountPlugins)
	window.UserAccountPlugins = [];
