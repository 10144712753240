
const caches = [
	{
		name: 'Users',
		options: {
			table: 'v_active_users',
			schema: 'grape'
		}
	},
	{
		name: 'Networks',
		options: {
			table: 'network',
			schema: 'grape'
		}
	},
	{
		name: 'AccessRoles',
		options: {
			table: 'v_access_roles',
			schema: 'grape'
		}
	},
	{
		name: 'UserSettings',
		options: {
			table: 'user_setting_lookup',
			schema: 'grape'
		}
	}

];

export default caches;


