
class GrapeUIPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;

		Grape.UsersPlugin = this;
	}

	async onInit()
	{
		this.Grape.currentSession = null;
		this.Grape.currentSessionObservable = ko.observable(null);

		let data = await Grape.fetches.getJSON('/api/session/info', {}, {cache: 'no-cache'});
		if (data.status == 'OK')
		{
			this.Grape.config.public_settings = data.public_settings || {};
			this.setSession(data.session, null);
		}
	}

	async setSession(session, trigger_hook='onSessionChange')
	{
		if (session == null)
		{
			Grape.currentSession = null;
			localStorage.removeItem('session_id');
			localStorage.removeItem('roles');
			localStorage.removeItem('username');
		}
		else
		{
			Grape.currentSession = JSON.parse(JSON.stringify(session));
			localStorage.setItem('session_id', session.session_id);
			if (session.roles)
				localStorage.setItem('roles', session.roles);
		}
		this.Grape.currentSessionObservable(session);
		if (trigger_hook)
			await Grape.plugins.triggerPluginHooks(trigger_hook, [Grape.currentSession]);
	}
}

export default GrapeUIPlugin;

