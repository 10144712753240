
// DIALOGS
import recover_pw_dialog from './recover_password.js';
import new_password_dialog from './new_password_dialog.js';

let dialogs = [
	recover_pw_dialog,
	new_password_dialog
];

export default dialogs;
