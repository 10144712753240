import template from './new_password_dialog.html';

/**
 * @class
 * @name NewPasswordDialogViewModel
 * @param {object} page - Page to which this ViewModel belongs
 */
class NewPasswordViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.show_totp = ko.observable(false);
		this.otp = ko.observable();
		this.password1 = ko.observable('');
		this.password2 = ko.observable('');
		this.error_message = ko.observable('');
		this.user = null;
		this.load();
	}

	async getCurrentUser ()
	{
		try{
			let response = await fetch('api/user/-1', {
				method:'GET',
				headers: { 'Content-Type': 'application/json' },
			});
			if (response.ok) {
				let data = await response.json();
				this.user = data.user;
				return true;
			}
		} catch (err) {
			console.error('Error getting current user', err);
		}
		return false;
	}

	async updateUserPassword (newPassword)
	{
		if (!this.user)
		{ 
			console.error('User data not available');
			return false;
		}

		this.user.password = newPassword;

		try {
			let response = await fetch('/api/user', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json'},
				body: JSON.stringify(this.user),
			});
			return response.ok;
		} catch (err) { 
			console.error('Error updating user password', err);
			return false;
		}
	}

	async load ()
	{ 
		let isCurentUserLoaded = await this.getCurrentUser();
		if (isCurentUserLoaded)
		{
			this.password1('');
			this.password2('');
		}
		else
			console.error('Failed to load user data');
	}

	async btnSave_click ()
	{
		this.error_message('');
		let p1 = this.password1();
		let p2 = this.password2();

		if (p1.length < 6)
		{
			this.error_message('Password must be longer than 6 characters');
			return;
		}

		if (p1 != p2)
		{
			this.error_message('The two passwords you entered does not match');
			return;
		}

		let isCurentUserLoaded = await this.getCurrentUser();
		if (!isCurentUserLoaded)
		{
			this.error_message('Failed to load user data');
			return;
		}

		let isPasswordUpdated = await this.updateUserPassword(p1);
		if (isPasswordUpdated)
		{
			Grape.alerts.alert({
				type:'success',
				title: 'Password Saved',
				message: 'Your new password has been saved',
				onclose: () => { 
					
				}
			});
			
			this.dialog.close(null);
		}
		else
			this.error_message('Error updating password');
	}

	btnClose_click()
	{
		this.dialog.close(null);
	}
}

/**
 * @class
 * @name NewPasswordDialog
 * @param {object} bindings - Bindings
 */
class NewPasswordDialog
{
	constructor (bindings)
	{
		/*
		Grape.shortcut(this, 'enter', () => {
			this.btnsave_click();
		});
		*/
		this.viewModel = new NewPasswordViewModel(this);
	}
}

export default {
	name: 'NewPassword',
	dialog_class: NewPasswordDialog,
	template: template,
	provider: "ps"
}
